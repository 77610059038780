import { Environments } from './environments.interface';

export const development: Environments = {
	baseUrl: 'https://tprofil.mrm-etagi.com',
	apiUrl: 'https://tprofil.mrm-etagi.com/api',

	reportsApiUrl: 'https://new.etagidata.ru/reports',
	aggregatorsApiUrl: 'https://aggregators-backend.data-etagi.ru',
	demarkBotApiUrl: 'https://markbot.etagi.pro/api',
  scoringApiUrl: 'https://tscoring.mrm-etagi.com/api/report',
  smmApiUrl: 'https://vk_mrm_backend.data-etagi.ru',

	leadsMonitorBaseUrl: 'https://tleads.mrm-etagi.com',
	leadsMonitorApiUrl: 'https://tleads.mrm-etagi.com/api',
	leadsMonitorWsUrl: 'wss://tleads.mrm-etagi.com/ws',

	budgetsBaseUrl: 'https://tbdg.mrm-etagi.com',
	budgetsApiUrl: 'https://tbdg.mrm-etagi.com/api',
	budgetsWsUrl: 'wss://tbdg.mrm-etagi.com',

  handbooksApiUrl: 'https://thandbook.mrm-etagi.com/api',
  chatGptApiUrl: 'https://new.etagidata.ru/mrm/chatgpt',
	storageApiUrl: 'https://tstorage.mrm-etagi.com/api',
	settingsApiUrl: 'https://tsetting.mrm-etagi.com/api',
	contentApiUrl: 'https://content.etagi.pro',
	notificationsApiUrl: 'https://tnotifications.mrm-etagi.com',

	telegramChatUrl: 'https://t.me/+f6TyNd3ryzxkNzc6',
  telegramApiUrl: 'https://telegram.mrm-etagi.com/api',
};
